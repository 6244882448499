.games-ctn {
  position: relative;

  div {
    font-family: 'Montserrat';
  }

  .text-white {
    color: var(--text);
  }

  .title-border-line {
    height: 1px;
    width: 100%;
    background: var(--background-gradient);
    position: absolute;
    top: 11px;

    @media screen and (max-width: 720px) {
      top: 12px;
    }
  }

  .heading {
    z-index: 1111;
    position: relative;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text);
    background: #CF8D2E;
    width: fit-content;
    padding: 6px 7px;
    border-radius: 0 10px 0 10px;
    margin: 14px auto 16px auto;

    @media screen and (max-width: 720px) {
      margin: 8px auto 9px auto;
    }

    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 10.3506px;
      line-height: 13px;

      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;

      color: #FFFFFF;

      // font-weight: 600;
      // font-size: 24px;
      // line-height: 16px;
      // text-transform: capitalize;
      // color: rgba(255, 255, 255, 0.6);
      @media screen and (max-width: 720px) {

        font-weight: 700;
        font-size: 10.3506px;
        line-height: 13px;
      }

    }

    .slider-btn {
      .btn {
        background: #242F39 !important;
        border-radius: 0;
        padding: 8px 13px;

        @media screen and (max-width: 720px) {
          padding: 2px 10px 2px 11px;
        }

        .MuiButton-label {
          background: #242F39 !important;
          color: var(--text) !important;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: capitalize;
          font-feature-settings: 'ss05' on;

          @media screen and (max-width: 720px) {
            font-weight: 500;
            font-size: 10px;
            line-height: 25px;
            height: 25px;
          }
        }
      }

      .btn1 {
        background: var(--active-link);
        border-radius: 15px;
        margin-left: 10px;

        .MuiButton-label {
          text-transform: capitalize;
          background: var(--active-link);
          font-weight: bold;
        }
      }
    }
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    // height: 300px;
    height: 175px;

    @media screen and (max-width: 720px) {
      height: 139px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .w-100 {
    width: 100%;
  }

  .img-container {
    border: var(--card-border);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3.75746px;
  }

  .container1 {
    position: relative;
    width: 100%;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 3.75746px;
  }

  .dc-img {
    display: block;
    width: 100%;
    height: 150px;
    padding: 13px 11px 0 11px;
    background-color: var(--card-background);
    border-radius: 3.75px 3.75px 0 0;
    height: 145px;
    image-rendering: unset !important;

    @media screen and (max-width: 720px) {
      height: 80px;
      width: 100%;
      padding: 6px 6px 0 6px;
      border-radius: 2px 2px 0 0;
    }
  }

  .game-name {
    // height: 36px;
    background-color: var(--card-background) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12.3px 0 12.7px 14.4px;
    width: 100%;
    color: var(--text);
    align-items: start;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 3.75px 3.75px;

    @media screen and (max-width: 720px) {
      width: 100%;
      padding: 6px 0 6px 6px;
      border-radius: 0 0 2px 2px;
    }

    .info-img {
      height: 16.5px;
      width: 16.5px;
      cursor: pointer;
    }

    .name {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;

      color: var(--text) !important;
      text-align: left;
      cursor: pointer;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      // padding-left: 8px;

      @media screen and (max-width: 720px) {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
      }
    }

    .p-name {
      display: none;
      font-weight: 600;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      color: #fff !important;

      @media screen and (max-width: 720px) {
        font-size: 8px;
      }
    }
  }

  .overlay1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    border: 1px solid var(--border-color);
  }

  .container1:hover .overlay1 {
    opacity: 1;
  }

  .text1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: var(--active-link);
    opacity: 1;
    color: var(--footer-text-active);
    border-radius: 6px;
    padding: 6px 3px;
    cursor: pointer;

    .custom-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 12px;
      width: 100px;
      font-family: var(--font-family);
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.3s ease;
      background-color: var(--active-link);
      opacity: 1;
      color: var(--footer-text-active);
      font-weight: bold;
      position: relative;

      img {
        margin-right: 4px;
      }
    }
  }
}

.slot-games-ctn {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text);
    background: var(--bg1);
    height: 50px;
    border-radius: 8px;

    .types-tabs {
      .MuiTab-root {
        opacity: 1;
        background-color: var(--bg1);
        min-height: 35px;
        max-height: 35px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 8px;

        .MuiTab-wrapper {
          font-style: normal;

          font-size: 12px;
          display: flex;
          flex-direction: row !important;
          align-items: center;
          text-transform: uppercase;
          color: var(--teble-head) !important;
          word-wrap: break-word !important;

          @media screen and (max-width: 720px) {
            font-size: 10px;
          }

          img {
            margin-right: 10px;
          }
        }
      }

      .Mui-selected {
        opacity: 1;
        // background: var(--active-link) !important;
        border-bottom: 1px solid var(--active-link);

        .MuiTab-wrapper {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          @media screen and (max-width: 720px) {
            font-size: 10px;
          }
        }
      }
    }

    .title {
      font-size: 20px;
      padding: 6px;
      margin-left: 10px;
      text-transform: uppercase;


    }
  }

  .card-slot {
    height: 360px;
    border-radius: 8px;

    background: var(--bg1);

    &.p-6 {
      padding: 6px;
    }

    .card-desc {
      height: 100px;
      border-radius: 8px;
      background-color: var(--ion-secondary);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--text);
      text-align: center;
      margin-top: 6px;
      margin-bottom: 6px;

      .p-name {
        padding: 5px;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
      }

      .w-label {
        font-size: 11px;
        color: var(--text);
        padding: 4px;
      }

      .btn {
        background-color: var(--active-link);
        font-size: 12px;
        margin-top: 5px;
        border-radius: 8px;
        width: 45px;
        position: absolute;
        left: 40%;
        color: var(--text);
        height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    .slick-dots li button:before {
      opacity: 1;
      color: var(--text);
      font-size: 10px;
      margin-top: -25px;
    }

    .card-images {
      height: 355px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .slot-games-ctn {
    border-radius: 8px;

    background: var(--bg1);
    padding: 4px;

    .slot-container {
      height: 160px;
      border-radius: 8px;
      margin-bottom: 5px;
      margin-top: 5px;

      .slot-img {
        border-radius: 8px;
        height: 160px;
      }
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}