.reports-ctn {
  border-radius: 45px 45px 10px 10px;

  .tbl-ctn .MuiTableContainer-root {
    background-color: transparent !important;
    color: #000;

    .MuiTable-root {
      .MuiTableBody-root {
        .MuiTableRow-root {
          // border-bottom: 1px solid var(--border-color);
        }
      }

      .MuiTableHead-root {
        border: none;

        .MuiTableCell-head {
          color: #000 !important;
          font-weight: 600;
        }

        .MuiTableRow-head {
          border-bottom: none !important;

          .MuiTableCell-head {
            color: #000 !important;
            // border-bottom: 1px solid var(--border-color);
            padding: 1px 5px;

            @media screen and (max-width: 720px) {
              padding: 11px 5px;
            }
          }

          .MuiTableRow-body {
            .MuiTableCell-head {
              color: #000 !important;
            }
          }

          .MuiTableBody-root .MuiTableCell-body {
            padding: 12px 6px;
          }
        }
      }
    }
  }

  .no-data-row {
    // background-color: var(--ion-background);
    width: 100%;
    border-bottom: 0px !important;
    color: var(--text-primary);
    height: 40vh;
    padding-top: 15px;
    text-align: center;

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    color: var(--text);
    opacity: 0.4;
  }
}

.reports-ctn {
  border-radius: 40px 40px 10px 10px;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;

  .header-ctn {
    padding-top: 20px;

    ion-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .page-title {
      font-family: var(--font-family) !important;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      line-height: 42px;
      letter-spacing: 0.01em;
      float: left;
      margin-right: auto;
      margin-top: 20px;
      color: var(--button);
    }

    .img-page-title-row {
      display: inline-flex;
      align-items: center;
      // line-height: 42px;
      height: 24px;
      margin-right: auto;
      background-color: #cf8d2e;
      padding: 0 10px;
      border-radius: 0 8px 0 8px;
      margin: auto;
      z-index: 11;

      @media screen and (max-width: 720px) {
        margin-top: 12px;
        margin-bottom: 6px;
      }

      .title {
        color: #fff !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10.3506px;
        line-height: 13px;
      }
    }
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: var(--filter-bg);
    padding: 8px 4px;

    .clim-action-web {
      margin-top: 15px;
    }

    .select-filter {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      width: 20%;

      .select-filter-label {
        font-size: x-small;
        color: var(--text);
      }

      .select-filter-control {
        margin-top: 0.1rem;
        min-width: 180px;
        // max-width: 220px;
        color: rgba(255, 255, 255, 0.6);
        background-color: #3b3b3b;
        border: 0;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        height: 35px;
      }
    }

    .date-section {
      display: flex;
      flex-direction: row;
      width: 60%;

      .date-filter {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        // width: 40%;

        .date-filter-label {
          font-size: x-small;
          color: var(--text);
        }

        .date-filter-control {
          margin-top: 1px;
          border: 0px !important;
          background-color: #3b3b3b !important;
          color: rgba(255, 255, 255, 0.6);
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          width: 100%;

          .MuiInputBase-root .MuiSvgIcon-root {
            color: rgba(255, 255, 255, 0.6);
          }

          .MuiInputBase-input {
            background-color: #3b3b3b !important;
            color: rgba(255, 255, 255, 0.6);
            font-family: 'Montserrat';
            font-size: 12px;
          }

          .MuiInputBase-root {
            height: 35px;
            width: 100%;
            border: 0;

            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              padding: 4px 16px;
              background-color: #3b3b3b !important;
            }
          }
        }
      }
    }

    .readonly {
      background: rgba(207, 207, 207, 0.5);
    }
  }

  .breadcrumbs-row {
    width: 100%;
    margin: 10px 0;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    color: var(--button);

    .breadcrumbs {
      align-items: center;
      display: flex !important;
      justify-content: flex-start !important;
      cursor: pointer;

      .prev-level-link {
        align-items: center;
        display: flex;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .MuiPaper-root {
    position: relative;
  }

  .myb-table {
    font-family: 'Montserrat';
    position: relative;

    // background-color: var(--ion-background);
    // .MuiTableBody-root {
    //   background-color: var(--ion-background) !important;
    // }
    .MuiTableHead-root {
      background: linear-gradient(
        180deg,
        #000000 -31.82%,
        #464646 143.18%
      ) !important;

      .MuiTableCell-root {
        padding: 13.2px 24px 14px 16px;
        color: rgba(255, 255, 255, 0.6) !important;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    .MuiTableCell-root {
      // color: var(--text);
      font-family: 'Montserrat';

      div {
        // color: var(--text);
        font-family: 'Montserrat';
      }
    }
  }

  .content-ctn {
    margin-top: 1rem;
    border-radius: 6px;
    margin: 15px 20px;
    position: relative;

    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            //   .MuiTableRow-root:nth-child(even) {
            //     background: var(--ion-card-background-contrast);
            //   }
            //   .MuiTableRow-root:hover {
            //     background-color: rgba(255, 167, 36, 0.2) !important;
            //   }
            .MuiTableBody-root {
              .MuiTableRow-body {
                // border: 1px solid var(--border-color);
                color: #000;
              }
            }

            .MuiTableRow-root {
              // border: 1px solid var(--border-color);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: #000;
              }
            }

            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background);
              color: #000 !important;

              .MuiTableCell-root {
                color: #000 !important;
              }
            }

            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background);

              .MuiTableCell-root {
                color: #000 !important;
                // border-bottom: 1px solid var(--border-color);
              }
            }
          }
        }
      }
    }
  }

  .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
    font-family: var(--font-family);

    &.profit {
      color: var(--ion-color-success) !important;
    }

    &.loss {
      color: var(--ion-color-danger) !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .reports-ctn {
    // border-radius: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 24px;
    // margin: 1% 2% 2% 2%;

    .header-ctn {
      background-color: transparent;
      padding-top: 0;

      ion-row {
        display: flex;
        width: 100%;

        .header-ctn-row {
          flex-direction: column;
        }
      }

      .page-title {
        font-family: var(--font-family);
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 42px;
        letter-spacing: 0.01em;
        text-align: center;
        margin-right: auto;
        margin-top: 6px;
      }

      span,
      div {
        // color: var(--text) !important;
      }

      .img-page-title-row {
        // display: inline-flex;
        // align-items: center;
        // font-size: 22px;
        // width: 100%;
        // justify-content: flex-start;
        // margin-bottom: 5px;

        .title {
          // font-size: 24px;
          // font-family: var(--font-family);
          // color: #000;
        }
      }
    }

    .filters-row {
      margin-top: 0.2rem;
      display: flex;
      flex-wrap: wrap;

      .select-filter {
        margin-left: 0.6rem;
        margin-right: 0;
        width: unset;
        flex: 1;

        .select-filter-label {
          font-size: 11px;
          color: white !important;
          font-family: 'Montserrat';
        }

        .select-filter-control {
          min-width: 160px;
          max-width: unset;
          margin-top: 0.1rem;
          color: rgba(255, 255, 255, 0.5);
          // background-color: var(--input-background-color);
          // border: 1px solid var(--border-color);
          border-radius: 4px;
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          height: 40px;
        }
      }

      .mr-12 {
        margin-right: 12px !important;
      }

      .ml-12 {
        margin-left: 12px !important;
      }

      .date-section {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        width: 100%;

        .date-filter {
          margin-left: 0;
          margin-right: 0;
          width: unset;
          flex: 1;

          &.ml-12 {
            margin-right: 10px !important;
          }

          .date-filter-label {
            font-size: 11px;
            color: var(--text) !important;
            font-family: 'Montserrat';
          }

          .date-filter-control {
            margin-top: 0%;
            // border: 1px solid var(--border-color);
            // background-color: uns;
            // border-radius: 4px;
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            height: 40px;
            border-radius: 4px;

            .MuiInputBase-root {
              width: 150px;
              height: 40px;

              .MuiInputBase-input,
              .MuiOutlinedInput-input {
                padding: 4px 16px;
                // background-color: var(--input-background-color) !important;
              }
            }
          }
        }
      }

      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }

    .content-ctn {
      margin-top: 1rem;
      border-radius: 6px;
      margin-left: 11px;
      margin-right: 11px;

      .table-ctn,
      .tbl-ctn {
        .MuiTableContainer-root {
          .MuiTable-root {
            .MuiTableBody-root {
              //   .MuiTableRow-root:nth-child(even) {
              //     background: var(--ion-card-background-contrast);
              //   }
              //   .MuiTableRow-root:hover {
              //     background-color: rgba(255, 167, 36, 0.2) !important;
              //   }
              .MuiTableBody-root {
                .MuiTableRow-body {
                  color: #000;
                }
              }

              .MuiTableRow-root {
                .MuiTableCell-body,
                .MuiSvgIcon-root {
                  color: #000;
                  white-space: nowrap;
                }
              }

              .MuiTableRow-root.back-odd-row {
                background: var(--back-odd-background);
                color: #000 !important;

                .MuiTableCell-root {
                  color: #000 !important;
                }
              }

              .MuiTableRow-root.lay-odd-row {
                background: var(--lay-odd-background);

                .MuiTableCell-root {
                  color: #000 !important;
                }
              }
            }
          }
        }
      }
    }

    .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
      font-family: var(--font-family);

      &.profit {
        color: var(--ion-color-success) !important;
      }

      &.loss {
        color: var(--ion-color-danger) !important;
      }
    }
  }
}

.reports-ctn
  .tbl-ctn
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableCell-head,
.reports-ctn
  .tbl-ctn
  .MuiTableContainer-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-head {
  background: linear-gradient(180deg, #000000 -31.82%, #464646 143.18%);
  color: rgba(255, 255, 255, 0.6) !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  // padding-left: 15px;
  padding: 14px 16px;
}

tbody.MuiTableBody-root.myb-table-body {
  background: var(--market-table-row) !important;
}

.MuiTableBody-root {
  background: var(--market-table-row) !important;
}
