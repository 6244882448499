.dialog-login {
  .modal-close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;

    color: var(--text-primary) !important;

    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--text-primary) !important;
      }
    }
  }

  .MuiPaper-root {
    border-radius: 0;
    border: 2px solid #CF8D2E;
    // max-width: unset;

    @media screen and (max-width: 720px) {
      height: fit-content;
      margin: 0 16px;
    }

  }

  .dialog-div-body {
    background: var(--ion-background-color);
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--ion-text-white);
    overflow: hidden;
    width: 525px;
    height: 70%;
    overflow-y: auto;
    height: 80vh;

    position: relative;

    &.transaction-details-modal {
      width: unset;
      height: unset;

      .reports-ctn {
        margin-left: 0;
      }
    }
  //  padding: 15px;
  //  width: 100%;
  //  height: 700px;

    @media screen and (max-width: 720px) {
      width: unset;
      // margin: 0 16px;
    }

    .close {
      color: var(--text-primary);
    }

    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 25px;
      height: 25px;
      overflow: hidden;
      top: 10px;
      right: 10px;
      z-index: 9999;
      border-radius: 50px;
      cursor: pointer;

      svg path {
        fill: var(--text);
      }

      .go-arrow {
        color: var(--text);
        font-family: courier;
        height: 20px;
        margin-top: -3px;
      }
    }
  }

  .dialog-div-body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: var(--background-image-opacity);
    background-image: url(../../assets/images/home/homeview/background.svg);
  }
}

@media screen and (max-width: 720px) {
  .dialog-login {
    padding: 0px !important;
    margin: 0px;

    .dialog-div-body {
      min-height: 100% !important;
      max-height: 100% !important;
      // background-size: 100% 100%;
      display: block;
      justify-content: center;
      align-items: center;
    }
  }
}